.contact {
	width: 80%;
	display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-inline: auto;
	align-items: center;
}

h5 {
	font-size: 1rem;
	font-weight: 800;
}

@media screen and (max-width: 1024px) {
	.heading {
		font-size: 1.6rem;
	}
	.contact {
		width: 95%;
		margin: 2rem auto 4rem;
	}
}

form {
	display: flex;
	flex-direction: column;
	gap: 1rem;
    width: 60%;
    margin-inline: auto;
}
input,
textarea {
	width: 100%;
	padding: 1.5rem;
	border-radius: 0.5rem;
	background: transparent;
	border: 2px solid var(--color-secondary-dark);
	resize: none;
	color: var(--color-secondary-light);
}

/* Media queries for medium devices */
@media screen and (max-width: 1024px) {
    form {
        width: 100%;
    }
	.container.contact__container {
		grid-template-columns: 1fr;
		gap: 2rem;
	}
}
/* Media queries for Small devices */
@media screen and (max-width: 600px) {
	.container.contact__container {
		width: var(--container-width-sm);
	}
}

.btn-align{
    margin-inline: auto;
}
.portfolio {
	width: 80%;
	margin-inline: auto;
	display: flex;
	flex-direction: column;
	gap: 1rem;
	text-align: center;
}

.portfolio h1 {
	font-size: 2.5rem;
	color: var(--color-accent);
}
.portfolio h5 {
	font-size: 1rem;
	font-weight: 800;
}

@media screen and (max-width: 1024px) {
	.portfolio {
		width: 95%;
	}
	.portfolio h1 {
		font-size: 1.6rem;
	}
	.portfolio {
		width: 95%;
		margin: 2rem auto 4rem;
	}
}

.portfolio__container {
	width: 100%;
	margin-inline: auto;
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	gap: 2rem;
	overflow: hidden;
}


@media screen and (max-width: 1024px) {
	.portfolio__container {
		overflow-y: hidden;
	}
}
.portfolio__item {
	background-color: var(--color-secondary-dark);
	text-align: start;
	padding: 1.2rem 1rem;
	border-radius: 10px;
	border: 1px solid transparent;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	gap: 1rem;
	transition: var(--transition);
}

.details{
	width: 50%;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	gap: 1rem;
}

.button-flex {
	display: flex;
	justify-content: space-between;
}

.portfolio__item:hover {
	border-color: var(--color-accent);
	background: transparent;
}
.portfolio__item-image {
	width: 50%;
	overflow: hidden;
}
.portfolio__item-image img{
	border-radius: 5px;
} 
.portfolio__item-cta {
	display: flex;
	justify-content: space-between;
	gap: 2rem;
	margin-bottom: 1rem;
}
.desc {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
}
/* Media queries for medium devices */
@media screen and (max-width: 1024px) {
	.portfolio__container {
		grid-template-columns: 1fr 1fr;
		gap: 1.2rem;
	}
	.portfolio__item{
		flex-direction: column;
	}
	.portfolio__item-image, .details{
		width: 100%;
	}
}
/* Media queries for small devices */
@media screen and (max-width: 600px) {
	.portfolio__container {
		width: 95%;
		grid-template-columns: 1fr;
		gap: 1rem;
	}
}

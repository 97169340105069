@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");

* {
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	box-sizing: border-box;
	list-style: none;
	text-decoration: none;
}
:root {
	--color-dark-primary: #1f1f38;
	--color-secondary-light: #BC8CF2;
	--color-secondary-dark: #60009466;
	--color-accent: #AA14F0;
	--color-dark-text: #EEEEEE;

	--color-light-primary: #EEEEEE;
	--color-secondary: #BC8CF2;
	--color-accent: #AA14F0;
	--color-ligth-text: #1f1f38;

	--color-bg:#1f1f38 ;
	--color-bg-variant: #2c2c6c;
	--color-primary: #4db5ff;
	--color-primary-variant: rgba(77, 181, 255, 0.4);
	--color-white: #fff;
	--color-light: rgba(255, 255, 255, 0.6);
	--transition: all 400ms ease;
	--container-width-lg: 75%;
	--container-width-md: 86%;
	--container-width-ms: 90%;
}
html {
	scroll-behavior: smooth;
}
::-webkit-scrollbar {
	display: none;
}
body {
	font-family: "Poppins", sans-serif;
	background: var(--color-bg);
	color: var(--color-dark-text);
	line-height: 1.7;
	background-color: #040100;
}
.container {
	width: var(--container-width-lg);
	margin: 0 auto;
}

h1 {
	font-size: 2rem;
	line-height: 2rem;
}

@media (min-width: 35rem) {
	h1 {
		font-size: 3rem;
		line-height: 2.6rem;
	}
}
section {
	margin-top: 8rem;
}
section > h2,
section > h5 {
	text-align: center;
	color: var(--color-light);
}
section > h2 {
	color: var(--color-primary);
	margin-bottom: 3rem;
}
.text-light {
	color: var(--color-light);
}
a {
	color: var(--color-primary);
	transition: var(--transition);
}

a:hover {
	color: var(--color-white);
}
.btn {
	width: max-content;
    display: flex;
    justify-content: space-evenly;
    gap: 0.5rem;
    align-content: center;
    padding: 0.5rem 1.5rem;
	color: var(--color-dark-text);
    font-weight: 700;
	border-radius: 0.4rem;
	cursor: pointer;
	border: 1px solid var(--color-accent);
	transition: var(--transition);
}
.btn:hover {
	background: var(--color-white);
	color: var(--color-bg);
	border-color: transparent;
}
.btn-primary {
	background: var(--color-accent);
	color: var(--color-dark-text);
}
img {
	display: block;
	width: 100%;
	object-fit: cover;
}

/* Media queries for medium devices */
@media screen and (max-width: 1024px) {
	.container {
		width: var(--container-width-md);
	}
	section {
		margin-top: 6rem;
	}
}

/* Media queries for small  devices */
@media screen and (max-width: 600px) {
	.container {
		width: var(--container-width-ms);
	}
	section > h2 {
		margin-bottom: 2rem;
	}
}

.name{
	color: var(--color-secondary-light);
}

input,textarea,button{
	font-family: inherit;
}

.heading {
	margin-inline: auto;
	font-size: 2.5rem;
	color: var(--color-accent);
}
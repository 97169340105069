header {
	padding-top: 10rem;
}
.intro{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 0.6rem;
}
h1 {
	font-weight: 800;
}
.header__container {
	display: flex;
	text-align: left;
	height: 100%;
}
.cta {
	display: flex;
	gap: 1.2rem;
	justify-content: flex-start;
}
.heroSection{
	width: 60%;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	gap: 2rem;
}
.header__social {
	display: flex;
	width: 80%;
	max-width: 22rem;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 0.8rem;
}
.lines{
	content: "";
	width: 20%;
	height: 1px;
	background-color: var(--color-accent);
}

.midPannel{
	margin-top: 1rem;
	width: 40%;
	margin-inline: auto;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
	gap: 1rem;
}
.heroImage {
	width: 100%;
	max-width: 25rem;
}

/* Media queries for medium devices */
@media screen and (max-width: 1024px) {
	.subPara{
		color: var(--color-light);
	}
	.cta {
		justify-content: center;
	}
	.heroSection{
		width: 100%;
	}
	.midPannel{
		width: 100%;
	}
	.header__container {
		flex-direction: column;
		text-align: center;
	}
	header {
		padding-top: 6rem;
	}
}

/* Media queries for Small devices */
@media screen and (max-width: 600px) {
	header {
		padding-top: 6rem;
	}
}
